



























































































































































































































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import {
    mdiNumeric10Circle,
    mdiNumeric1Circle,
    mdiNumeric2Circle,
    mdiNumeric3Circle,
    mdiNumeric4Circle,
    mdiNumeric5Circle,
    mdiNumeric6Circle,
    mdiNumeric7Circle,
    mdiNumeric8Circle,
    mdiNumeric9Circle,
} from "@mdi/js";
import ContactUsLink from "@/components/ContactUsLink.vue";
@Component({
    components: { ContactUsLink, BackButton },
})
export default class FaqWhatDoIDoIfMyDStvChannelsAreNotShowing extends Vue {
    oneIcon: string = mdiNumeric1Circle;
    twoIcon: string = mdiNumeric2Circle;
    threeIcon: string = mdiNumeric3Circle;
    fourIcon: string = mdiNumeric4Circle;
    fiveIcon: string = mdiNumeric5Circle;
    sixIcon: string = mdiNumeric6Circle;
    sevenIcon: string = mdiNumeric7Circle;
    eightIcon: string = mdiNumeric8Circle;
    nineIcon: string = mdiNumeric9Circle;
    tenIcon: string = mdiNumeric10Circle;
}
