










import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ContactUsLink extends Vue {
    @Prop({ required: true, type: Boolean, default: false }) bold!: boolean;
}
